<template>
  <l-default>
    <OTitle>Call Success</OTitle>
    <CShareThisInline/>
    <h3 class="">Your call has been initiated.</h3>
    <p>
      Our system will call your phone - please pick up so we can bridge the call with the recipient.
      Do not worry, your number will not be revealed, we just need to bridge the call, so you can talk without dialing
      access point numbers.
    </p>
    <OForm @submit="submit">
      <FormButtons>
        <OFormSubmitButton>
          Home
        </OFormSubmitButton>
      </FormButtons>
    </OForm>
    <CShareThisFollow/>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import OForm from '../../objects/forms/OForm'
import OFormSubmitButton from '../../objects/forms/OFormSubmitButton'
import FormButtons from '../../objects/forms/FormButtons'
import CShareThisInline from '../../components/CShareThisInline'
import CShareThisFollow from '../../components/CShareThisFollow'

export default {
  name: 'PageCallSuccess',
  components: { OTitle, LDefault, OForm, OFormSubmitButton, FormButtons, CShareThisInline, CShareThisFollow },
  methods: {
    submit () {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss">
  h3 {
    color: $primary;
  }
</style>

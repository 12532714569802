<template>
  <form
    novalidate
    v-on="listeners"
    :class="classes"
  >
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'OForm',
  provide () {
    return {
      form: this
    }
  },
  data: function () {
    return {
      dirty: false,
      showValidity: false,
      submitted: false,
      valid: false
    }
  },
  computed: {
    classes () {
      return [
        'o-form',
        this.dirty ? 'form-is-dirty' : 'form-is-pristine',
        this.showValidity ? 'form-show-validity' : 'form-hide-validity',
        this.submitted ? 'form-is-submitted' : 'form-is-fresh',
        this.valid ? 'form-is-valid' : 'form-is-invalid'
      ]
    },
    listeners () {
      return {
        ...this.$listeners,
        change: this.onChange,
        input: this.onInput,
        submit: this.onSubmit
      }
    }
  },
  methods: {
    checkValidity () {
      this.valid = this.$el.checkValidity()
      if (this.valid) {
        this.showValidity = true
      }
      return this.valid
    },
    reportValidity () {
      if (this.checkValidity()) return true
      for (const ctrl of this.$el.elements) {
        if (ctrl.checkValidity()) continue
        ctrl.focus()
        ctrl.reportValidity()
        return false
      }
      throw new Error('Invalid state - form.checkValidity reported invalid, but form.elements did not find it')
    },
    onChange (event) {
      if (event.target.form !== this.$el) return
      this.dirty = true
      this.checkValidity()
      this.$emit('change', this)
    },
    onInput (event) {
      if (event.target.form !== this.$el) return
      this.dirty = true
      this.checkValidity()
      if (this.valid) {
        this.showValidity = true
      }
      this.$emit('input', this)
    },
    onSubmit (event) {
      event.preventDefault()
      this.showValidity = true
      this.reportValidity()
      this.$emit('submit', this)
    }
  }
}
</script>

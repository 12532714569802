<template>
  <o-button
    theme="primary"
    type="submit"
  >
    <slot></slot>
  </o-button>
</template>

<script>
import OButton from '../../objects/OButton'

export default {
  name: 'OFormSubmitButton',
  components: { OButton },
  inject: ['form'],
  data: function () {
    return {
      valid: false
    }
  },
  methods: {
    checkValidity () {
      this.valid = this.$el.form ? this.$el.form.checkValidity() : false
    }
  }
}
</script>

<template>
  <div class="btn-group" :class="`btn-group--${variant}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormButtons',
  props: {
    variant: String
  }
}
</script>

<style lang="scss">
  .btn-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 0.5rem;
    margin-bottom: $vertical-margin;

    & > * {
      flex: 1 1 auto;
    }

    &--horizontal .o-btn + .o-btn {
      margin-left: $horizontal-margin;
    }
  }
</style>
